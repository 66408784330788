<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" xl="10">
                <custom-page-title page-title="menu.help.company-registration.courts"></custom-page-title>

                <v-simple-table>
                    <tbody>
                    <tr>
                        <td colspan=2>
                            <p>Az <b>elektronikus cégiratokba való betekintés, hiteles
                                elektronikus cégkivonat, cégmásolat vagy cégbizonyítvány iránti kérelem</b> a
                                következő e-mail címre küldendő:</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Céginformációs és Elektronikus Cégeljárásban Közreműködő
                                Szolgálat</p>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>
                            <p>Céginformáció iránti kérelmek elektronikus úton:</p>
                        </td>
                        <td>
                            <p><a href="mailto:cegszolgalat@im.gov.hu">cegszolgalat@im.gov.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Beszámoló iránti kérelmek elektronikus úton:</p>
                        </td>
                        <td>
                            <p><a href="mailto:e-beszamolo@im.gov.hu">e-beszamolo@im.gov.hu</a></p>
                        </td>
                    </tr>

                    <tr>
                        <td colspan=2>
                            <p>A <b>cégbíróságoknak címzett beadványok (cégbejegyzési
                                és változásbejegyzési kérelmek, egyéb beadványok, bejelentések)</b> ezen a Portálon keresztül, vagy az
                                illetékes cégbíróság alábbi táblázatban szereplő - a Céginformációs Szolgálat által
                                üzemeltett - e-mail címére küldendők:</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Fővárosi Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:fovaros@iroda.e-cegjegyzek.hu">fovaros@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Pécsi Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:baranya@iroda.e-cegjegyzek.hu">baranya@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Kecskeméti Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:bacs@iroda.e-cegjegyzek.hu">bacs@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Gyulai Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:bekes@iroda.e-cegjegyzek.hu">bekes@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Miskolci Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:borsod@iroda.e-cegjegyzek.hu">borsod@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Szegedi Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:csongrad@iroda.e-cegjegyzek.hu">csongrad@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Székesfehérvári Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:fejer@iroda.e-cegjegyzek.hu">fejer@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Győri Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:gyor@iroda.e-cegjegyzek.hu">gyor@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Debreceni Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:hajdu@iroda.e-cegjegyzek.hu">hajdu@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Egri Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:heves@iroda.e-cegjegyzek.hu">heves@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Szolnoki Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:szolnok@iroda.e-cegjegyzek.hu">szolnok@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Tatabányai Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:komarom@iroda.e-cegjegyzek.hu">komarom@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Balassagyarmati Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:nograd@iroda.e-cegjegyzek.hu">nograd@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Budapest Környéki Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:pest@iroda.e-cegjegyzek.hu">pest@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Kaposvári Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:somogy@iroda.e-cegjegyzek.hu">somogy@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Nyíregyházi Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:szabolcs@iroda.e-cegjegyzek.hu">szabolcs@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Szekszárdi Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:tolna@iroda.e-cegjegyzek.hu">tolna@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Szombathelyi Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:vas@iroda.e-cegjegyzek.hu">vas@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Veszprémi Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:veszprem@iroda.e-cegjegyzek.hu">veszprem@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Zalaegerszegi Törvényszék Cégbírósága</p>
                        </td>
                        <td>
                            <p><a href="mailto:zala@iroda.e-cegjegyzek.hu">zala@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan=2>
                            <p>Az <b>ítélőtábláknak</b> és a <b>kúriának</b> címzett
                                beadványok (cégbejegyzés fellebbezésével kapcsolatos beadványok ezen a Portálon keresztül, vagy az illetékes
                                bíróság alábbi táblázatban szereplő - a Céginformációs Szolgálat által üzemeltett -
                                e-mail címére küldendők.<br>
                                Felhívjuk a figyelmet, hogy a cégbejegyzési határozatok fellebbezését a
                                cégbíróságoknak kell benyújtani. Az ítélőtábláknak, illetve a kúriának
                                kizárólag abban az esetben küldjön közvetlenül beadványt, ha erre az
                                ítélőtábla (kúria) határozatban felszólítja.</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Fővárosi Ítélőtábla</p>
                        </td>
                        <td>
                            <p><a href="mailto:fovitb@iroda.e-cegjegyzek.hu">fovitb@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Debreceni Ítélőtábla</p>
                        </td>
                        <td>
                            <p><a href="mailto:debitb@iroda.e-cegjegyzek.hu">debitb@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Győri Ítélőtábla</p>
                        </td>
                        <td>
                            <p><a href="mailto:gyoritb@iroda.e-cegjegyzek.hu">gyoritb@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Pécsi Ítélőtábla</p>
                        </td>
                        <td>
                            <p><a href="mailto:pecsitb@iroda.e-cegjegyzek.hu">pecsitb@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Szegedi Ítélőtábla</p>
                        </td>
                        <td>
                            <p><a href="mailto:szegeditb@iroda.e-cegjegyzek.hu">szegeditb@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Kúria</p>
                        </td>
                        <td>
                            <p><a href="mailto:lb@iroda.e-cegjegyzek.hu">lb@iroda.e-cegjegyzek.hu</a></p>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import CustomPageTitle from "@/components/CustomPageTitle";

@Component({
    components: {
        CustomPageTitle
    },

})
export default class HelpCourts extends Vue {
}
</script>

<style scoped>

</style>